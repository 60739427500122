<div [ngClass]="{ 'modal-open': modalOpen }" class="tour-guide">
    <div class="tour-guide-container">
        <div class="tour-guide-title">
            ZEDS Tutorial
            <img class="tour-title-image" src="/assets/images/ziggy-smart.png" />
        </div>
        <div></div>
        <div *ngIf="showRootMenu" class="tour-guide-step-container">
            <div
                (click)="openStep(step)"
                *ngFor="let step of steps"
                [ngClass]="{ 'step-open': currentStep.id === step.id }"
                class="tour-guide-step"
            >
                <div class="step-name-container">
                    <div class="step-number">{{ step.id + 1 }}.</div>
                    <div class="step-name">{{ step.name }}</div>
                    <img
                        *ngIf="step.icon"
                        class="step-icon"
                        src="{{ step.icon }}"
                        style="width: 1.875rem; margin-bottom: 0.3125rem; margin-left: 0.3125rem; padding-right: 0"
                    />
                    <div class="step-toggle"></div>
                </div>
            </div>
        </div>
        <div *ngIf="showSubMenu" class="tour-guide-step-container subMenu">
            <div class="subMenuTitle">
                {{ currentStep.name }}
            </div>
            <div
                (click)="toggleSubStep(subStep)"
                *ngFor="let subStep of currentStep.subSteps"
                [ngClass]="{ 'current-step': currentSubStep.id === subStep.id, 'step-open': subStep.open }"
                class="tour-guide-sub-step"
            >
                <div class="step-name-container">
                    <div class="step-number">{{ subStep.id + 1 }}.</div>
                    <div class="step-name">{{ subStep.name }}</div>
                    <div (click)="toggleSubStep(subStep)" class="step-toggle"></div>
                </div>
                <div [innerHTML]="subStep.subText" class="subText"></div>
            </div>
        </div>
        <div class="tour-guide-button-container">
            <div
                (click)="prevStep()"
                [ngClass]="{ 'button-disabled': prevDisabled }"
                class="tour-guide-button button-left"
            >
                <div class="tour-guide-back-button"></div>
            </div>
            <div
                (click)="returnHome()"
                [ngClass]="{ 'button-disabled': homeDisabled }"
                class="tour-guide-button button-middle"
            >
                <div class="tour-guide-home-button"></div>
            </div>
            <div
                (click)="restartStep()"
                [ngClass]="{ 'button-disabled': restartDisabled }"
                class="tour-guide-button button-middle"
            >
                <div class="tour-guide-restart-button"></div>
            </div>
            <div
                (click)="nextStep()"
                [ngClass]="{ 'button-disabled': nextDisabled }"
                class="tour-guide-button button-right"
            >
                <div class="tour-guide-forward-button"></div>
            </div>
        </div>
    </div>
</div>
