<div class="boxFooter">
    <div>
        <img (click)="toggleExpand()" *ngIf="!fullScreen" class="expand-button" src="/assets/images/Expand.svg" />
        <img (click)="toggleExpand()" *ngIf="fullScreen" class="expand-button" src="/assets/images/Collapse.svg" />
    </div>
    <div class="longnames">
        <div id="configFullName">
            <div><strong>Full Config Name:</strong> {{ fullConfigName ?? '(not assigned)' }}</div>

            <img
                (click)="copy(fullConfigName)"
                *ngIf="fullConfigName"
                class="copyicon"
                src="/assets/svgs/copy.svg"
                style="height: 1.1875rem"
                title="Click to copy full config name for use in configs and sdks [{{ fullConfigName }}]"
            />
        </div>
        <div id="configTypeFullName">
            <div><strong>Full Config Type Name:</strong> {{ fullConfigTypeName }}</div>
            <img
                (click)="copy(fullConfigTypeName)"
                *ngIf="fullConfigTypeName"
                class="copyicon"
                src="/assets/svgs/copy.svg"
                style="height: 1.1875rem"
                title="Click to copy full config type name for use in configs and sdks [{{ fullConfigTypeName }}]"
            />
        </div>
    </div>
    <div>
        <div (click)="update()" class="button">
            <!--[matTooltipClass]="'tooltip update-disabled'"
  matTooltip="{{ fullScreen ? 'Cannot update config while in fullscreen mode' : '' }}"-->
            Save
        </div>
    </div>
</div>
